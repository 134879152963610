import React from 'react';

import ScrollDownItem from '@/core/components/ScrollDownItem';
import { ToastStatus } from '@/core/components/Toast/Toast';
import Actions from '@/core/lib/new-architecture/actions';
import { ReactivateAction } from '@/core/lib/new-architecture/domain/donation.domain';
import { useToastContext } from '@/core/lib/toast/toast.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface ReactivateActionComponentProps {
  action: ReactivateAction;
}

const ReactivateActionComponent: React.FC<ReactivateActionComponentProps> = ({ action }) => {
  const { t } = useTranslationContext(['common']);
  const { onReactivate, isLoading } = Actions.donation.useReactivate();
  const toast = useToastContext();

  const onClick = async () => {
    try {
      await onReactivate(action.donationId);
      toast?.open(t('reactivate.success', { ns: 'common' }), 2000, ToastStatus.SUCCESS);
    } catch {
      toast?.open(t('reactivate.error', { ns: 'common' }), 2000, ToastStatus.DANGER);
    }
  };

  return (
    <ScrollDownItem
      type="button"
      icon="clock"
      content={t('components.messages.message-list.giver-action-giver-reactivate', { ns: 'common' })}
      onClick={onClick}
      disabled={isLoading || action.isDisabled}
    />
  );
};

export default ReactivateActionComponent;
